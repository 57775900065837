import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import servicesGac from "../../assets/servicesGAC.json";
import Index from "../../layout/Index";
import "../../styles/layoutMain.css";
import { getPrice } from "../../service/getPrice";
import toast, { Toaster } from "react-hot-toast";

const LayoutMain = () => {
  const sliderRef = useRef(null);
  const [items, setItems] = useState([]);
  const progressRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (type) => {
    progressRef.current.style.animation = "none"; // Detiene la animación de la barra de progreso
    void progressRef.current.offsetWidth; // Trigger reflow para resetear la animación
    progressRef.current.style.animation = "progressAnimation 7s linear"; // Reinicia la animación de la barra de progreso

    if (type === "Next") {
      sliderRef.current.append(items[0]);
    } else {
      sliderRef.current.prepend(items[items.length - 1]);
    }
    setItems(Array.from(sliderRef.current.children));
  };

  const handleGetPrice = async () => {
    try {
      setLoading(true);
      // Verificar si ya tenemos la URL guardada en sessionStorage
      const storedPrice = sessionStorage.getItem("priceURL");

      // Si ya existe la URL, no hacemos la petición
      if (storedPrice) {
        return window.open(storedPrice, "_blank");
      } else {
        // Si no, realizamos la llamada para obtener la URL
        const response_price = await getPrice();

        if (response_price?.data?.msg === "Token invalid") {
          sessionStorage.clear(); // Limpiar sessionStorage si el token es inválido
          return navigate("/"); // Redirigir a la página de login
        }

        // Aseguramos que la respuesta es válida antes de almacenarla y abrirla
        if (response_price.length > 0) {
          const priceURL = response_price; // Suponiendo que response_price es la URL que necesitas
          sessionStorage.setItem("priceURL", priceURL); // Guardamos la URL en sessionStorage
          window.open(priceURL, "_blank"); // Abrimos la URL en una nueva pestaña
        }
      }
    } catch (error) {
      toast(
        <section>
          <header>
            <h2>¡Atención!</h2>
          </header>
          <p>
            Parece que no tenemos un correo electrónico registrado con tu cédula
            de ciudadanía. Para poder continuar, por favor, contáctanos a
            nuestro soporte a través de WhatsApp haciendo clic en el siguiente
            enlace:
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=573156935204&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda"
            target="_blank"
            role="button"
            aria-label="Contactar a soporte por WhatsApp"
            rel="noopener noreferrer"
            className="text-primary"
          >
            Contactar a Soporte
          </a>
          <p>
            {" "}
            Nuestro equipo estará encantado de ayudarte a resolver cualquier
            inconveniente.
          </p>
        </section>,
        {
          duration: 5000,
        }
      );
      console.error("Error al obtener el url price:", error); // Agregar manejo de errores
    } finally {
      setLoading(false);
    }
  };

  // Inicia el cambio de slider y la animación de la barra de progreso automáticamente cada 7 segundos
  useEffect(() => {
    const timerId = setTimeout(() => {
      handleChange("Next");
    }, 7000);
    return () => clearTimeout(timerId); // Limpia el temporizador al desmontar el componente
  }, [items]); // Ejecuta el efecto cada vez que cambia el array de items

  useEffect(() => {
    setItems(Array.from(sliderRef.current.children));
  }, []);

  return (
    <Index>
      <Toaster />

      <div className="progress-container">
        <div ref={progressRef} className="progress-bar"></div>
      </div>
      <main className="mainLayout">
        <h1 className="textWelcome">
          Bienvenido a tu próxima experiencia de viajes
        </h1>
        <ul ref={sliderRef} className="sliderServices">
          {servicesGac.map(
            ({ id, resource, category, text, action, actionLink }) => (
              <li
                className={"itemLayout"}
                key={id}
                style={{
                  backgroundImage: `url(${resource})`,
                }}
              >
                <div className="content">
                  <h1 className="titleSection">{category}</h1>
                  <p className="descriptionSection">{text}</p>

                  {actionLink ? (
                    <a
                      href={actionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="buttonAction"
                    >
                      Reservar
                    </a>
                  ) : (
                    <>
                      {action ? (
                        <NavLink className="buttonAction" to={action}>
                          Reservar
                        </NavLink>
                      ) : (
                        <>
                          {loading ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <button
                              disabled={loading}
                              className="buttonAction"
                              onClick={handleGetPrice}
                            >
                              Reservar
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </li>
            )
          )}
        </ul>
        <nav className="navOptions">
          <button
            onClick={() => handleChange("Prev")}
            className="btnItem prev"
            name="arrow-back-outline"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <button
            onClick={() => handleChange("Next")}
            className="btnItem next"
            name="arrow-forward-outline"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M8.293 4.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414-1.414L14.586 12 8.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </nav>
      </main>
    </Index>
  );
};

export default LayoutMain;
