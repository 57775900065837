import React, { useEffect, useState } from "react";
import confetti from "canvas-confetti";
import ReactGA from "react-ga";
import Index from "../layout/Index";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../service/getInfo";
import Load from "../common/Load";
import UserMora from "../common/UserMora";
import LayoutMain from "../components/swiper/LayoutMain";
import { useUserMora } from "../context/userMoraContext";
import ChangePassword from "../components/user/ChangePassword";
import { createAuditory } from "../service/auditory";
import toast, { Toaster } from "react-hot-toast";
import "../styles/welcome.css";
import useTimeOut from "../common/hook/useTimeOut";

const Booking = () => {
  useEffect(() => {
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
  }, []);

  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { setIsStateMora } = useUserMora();

  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
          localStorage.setItem(
            "state_mora",
            response.data.data.info.validaciones.mora
          );
          setIsStateMora(response.data.data.info.validaciones.mora);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const registerAuditory = async () => {
    try {
      await createAuditory({
        contrato: user.contracto.contrato,
        titular: user.afiliado1.titular,
        email: user.afiliado1.email || user.afiliado2.email,
        pagina: "priority",
      });
    } catch (error) {
      toast(
        <section>
          <header>
            <h2>¡Atención!</h2>
          </header>
          <p>
            Parece que no tenemos un correo electrónico registrado con tu cédula
            de ciudadanía. Para poder continuar, por favor, contáctanos a
            nuestro soporte a través de WhatsApp haciendo clic en el siguiente
            enlace:
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=573156935204&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda"
            target="_blank"
            role="button"
            aria-label="Contactar a soporte por WhatsApp"
            rel="noopener noreferrer"
            className="text-primary"
          >
            Contactar a Soporte
          </a>
          <p>
            {" "}
            Nuestro equipo estará encantado de ayudarte a resolver cualquier
            inconveniente.
          </p>
        </section>,
        {
          duration: 6000,
        }
      );
    }
  };

  useEffect(() => {
    getInfoUser();
    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de reserva",
      action: "Ingreso pagina de reserva",
      label: "Notificación ingreso pagina de reserva ",
    });

    confetti({
      spread: 660,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ["star"],
      colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
      particleCount: 50,
      scalar: 1.2,
    });
  }, []);

  useTimeOut(() => registerAuditory(), 2000);

  return (
    <Index>
      <Toaster />
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{ borderRadius: "1rem", border: "none" }}
          >
            <div className="card-mora" style={{ width: "100%" }}>
              ¡Descubre el mundo con Grupo Alianza Colombia! Pero antes,
              asegúrate de estar al día y no perderte ni un solo detalle de
              nuestros increíbles servicios de viaje. 🌍✈️
            </div>
          </div>
        </div>
      </div>
      {user ? (
        <>
          {user.validaciones.chpwd === "1" ? (
            <ChangePassword />
          ) : (
            <>
              {user.validaciones.mora === "1" ? (
                <div className="row centrar">
                  <div className="mt-5 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <UserMora text={user.validaciones.mmora.slice(37)} />
                  </div>
                </div>
              ) : (
                <LayoutMain />
              )}
            </>
          )}
        </>
      ) : (
        <Load />
      )}
    </Index>
  );
};

export default Booking;
