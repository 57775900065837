import axios from "axios";

const url =
  "https://mainpage-gac-api-production.up.railway.app/v1/api/auditory/create";

const createAuditory = async (payload) => {
  return await axios.post(url, payload);
};

export { createAuditory };
