import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../utils/baseUrl";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import Navbar from "../Navbar";
import ReactGA from "react-ga";
import { getPrice } from "../../service/getPrice";
import { Toaster, toast } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import "../../styles/login.css";

const INITIAL_USER = {
  contrato: "",
  password: "",
};

const Signin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(INITIAL_USER);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setData } = useContext(DataContext);
  const [captchaResponse, setCaptchaResponse] = useState("");
  const keyCaptcha = "6LfEe7QmAAAAAKPiHe16j9vqFfEjKZoCpUdjInMd";

  useEffect(() => {
    ReactGA.pageview(window.location.pathname); //Google Analytics
    setData(null);
    const isExpired = localStorage.getItem("token");

    if (isExpired) {
      setTimeout(() => {
        navigate("/reserva");
      }, "0");
    }
  }, [setData, navigate]);
  useEffect(() => {
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaResponse) {
      toast.error("Por favor, completa el reCAPTCHA");
      return;
    }
    ReactGA.event({
      category: "Pagina de inicio",
      action: "Sistema de autenticación priority",
      label: "Notificación solcitud inicio de sesión",
      value: 1,
    });
    setLoading(true);
    try {
      const url = `${baseUrl}/api/users/getuser`;
      const payload = { ...user };
      const response = await axios.post(url, payload, {
        header: "Content-Type Aplication/json",
      });
      if (response.data.status === 500) {
        toast.error("Usuario o contraseña Incorrectos, Intente Nuevamente", {
          style: {
            border: "none",
            borderBottom: "5px solid #ed0a26",
            color: "#ed0a26",
          },
          position: "top-center",
        });
      } else {
        localStorage.setItem("token", response.data.token);
        const response_price = await getPrice();
        localStorage.setItem("price", response_price);
        const temporizadorCerrarSesion = setTimeout(() => {
          cerrarSesion();
        }, 39 * 60 * 1000);
        function cerrarSesion() {
          localStorage.clear();
          navigate("/");
          clearTimeout(temporizadorCerrarSesion);
        }
        navigate("/reserva");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRecaptchaChange = (response) => {
    setCaptchaResponse(response);
  };

  return (
    <main className="container-fluid bg-login">
      <Navbar />
      <Toaster />
      <section className="mt-5 centrar bg-degrade">
        <div className="container mt-5">
          <div className="row d-flex justify-content-center align-items-center mt-5">
            <div className="col col-xl-4">
              <div className="Mycard">
                <div className="centrar">
                  <img
                    src="/images/AlianzaPriority2.webp"
                    alt="Grupoa Alianza Colombia"
                    width="157px"
                    height="57px"
                  />
                </div>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="inputGac">
                    <input
                      type="text"
                      placeholder="Digite su contrato"
                      name="contrato"
                      value={user.contrato.toUpperCase()}
                      onChange={handleChange}
                      maxLength={10}
                    />
                    <label htmlFor="name">Usuario</label>
                  </div>
                  <div className="inputGac">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Digite su contraseña"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Contraseña</label>
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 28 28"
                        className="icon_password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <g fill="#003893" fillRule="evenodd" clipRule="evenodd">
                          <path d="M17.747 15.415c.238-.54.372-1.143.372-1.413 0-2.348-1.824-4.25-4.073-4.25-2.25 0-4.073 1.902-4.073 4.25 0 2.347 2.037 3.887 4.073 3.887 1.303 0 2.462-.637 3.206-1.63a4.29 4.29 0 00.495-.844zm-3.701.349c.51 0 .975-.196 1.332-.518.432-.39.622-.965.622-1.244 0-1.174-.828-2.126-1.954-2.126-1.125 0-2.046.95-2.046 2.124 0 .81.92 1.764 2.046 1.764z"></path>
                          <path d="M1.092 14.272a.448.448 0 010-.545c.01-.012.019-.025.028-.039C4.78 8.343 9.32 5.5 14.014 5.5c4.689 0 9.224 2.836 12.882 8.17.069.101.104.205.104.33 0 .13-.04.24-.114.346C23.23 19.67 18.698 22.5 14.014 22.5c-4.705 0-9.257-2.857-12.922-8.228zm2.847-.92a1.04 1.04 0 000 1.294c3.135 3.898 6.665 5.729 10.075 5.729 3.41 0 6.94-1.83 10.075-5.729a1.04 1.04 0 000-1.294c-3.135-3.897-6.665-5.727-10.075-5.727-3.41 0-6.94 1.83-10.075 5.727z"></path>
                        </g>
                      </svg>
                    ) : (
                      <svg
                        className="icon_password"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 28 28"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <g fill="#000">
                          <path
                            fillRule="evenodd"
                            d="M22.693 1.55a.794.794 0 00-1.093.297l-2.447 4.297c-1.667-.78-3.392-1.18-5.139-1.18-4.693 0-9.233 2.882-12.894 8.3l-.015.021-.012.019a.46.46 0 000 .552c2.7 4.013 5.884 6.641 9.255 7.746L8.4 25.022a.817.817 0 00.293 1.108l.347.203a.794.794 0 001.092-.297L23.332 2.86a.817.817 0 00-.293-1.108l-.346-.203zm-4.601 6.457c-1.357-.597-2.727-.888-4.078-.888-3.41 0-6.94 1.854-10.075 5.805-.3.38-.3.932 0 1.311 2.35 2.962 4.922 4.746 7.499 5.454l1.348-2.366c-1.54-.49-2.813-1.86-2.813-3.741 0-2.38 1.824-4.308 4.073-4.308 1.038 0 1.986.41 2.705 1.087l1.341-2.354zm-2.453 4.307c-.346-.537-.916-.886-1.593-.886-1.125 0-2.046.963-2.046 2.152 0 .786.843 1.705 1.902 1.782l1.737-3.048z"
                            clipRule="evenodd"
                          ></path>
                          <path d="M14.687 22.176c4.444-.261 8.719-3.107 12.2-8.245A.605.605 0 0027 13.58a.571.571 0 00-.104-.335c-1.338-1.977-2.794-3.616-4.33-4.9l-1.06 1.86c.883.76 1.747 1.665 2.583 2.719.301.38.301.932 0 1.311-2.521 3.178-5.299 5-8.064 5.592l-1.338 2.35z"></path>
                        </g>
                      </svg>
                    )}
                  </div>
                  <div className="centrar mt-4">
                    <ReCAPTCHA
                      sitekey={keyCaptcha}
                      onChange={handleRecaptchaChange}
                      style={{
                        borderRadius: ".3rem",
                      }}
                    />
                  </div>
                  <button className="mt-3 btn-login" disabled={loading}>
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm"></span>{" "}
                        Cargando...
                      </>
                    ) : (
                      "Iniciar Sesión "
                    )}
                  </button>
                  <NavLink
                    to="/accounts/password/reset"
                    className="note centrar mt-3"
                  >
                    ¿Has olvidado la contraseña?
                  </NavLink>
                  <div className="centrar">
                    <a
                      className="text-title"
                      href="https://api.whatsapp.com/send?phone=573156935204&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda%20para%20ingresar%20al%20portal%20de%20clientes%20afiliados"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      ¿Necesitas ayuda?
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Signin;
